import React,{useEffect, useState} from 'react'
import style from './style.module.css';
import ReactPlayer from 'react-player/lazy'
import QAForm from './QAForm';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import garden from '../../assets/bg/garden.png';
import contentBtns_gifts from '../../assets/bg/contentBtns_gifts.png';
import balloon_clouds from '../../assets/bg/balloon_clouds.png';
import ValidSMS from '../modals/ValidSMS';
import AddUser from '../modals/AddUser';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { parentDetailsResp, userDetailsResp } from '../../api/api';
import { useHistory, useLocation } from "react-router-dom";
// import bigappsLogo from "../../assets/logo/bigappsLogo.png"
import MainScreenLoader from '../modals/MainScreenLoader';

const reactPlayerStyle = {
    overflow: "hidden",
    border: ".25vw solid rgba(77,105,169,.8)",
    // backgroundColor: "#F9F9F9",
    width: "100%",
    height: "100%",
    backgroundColor: '#F7F4E5',
    boxShadow: "0vw 0vw .8vh rgba(77,105,169,.5)",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",

    border: '.11vw solid #000', 
    borderRadius: '1vw',
    cursor: 'pointer',
    // position: 'relative'
    // backgroundColor: 'rgba(0,0,0,.8)'
}

const MainScreen = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
    const [validSMSModalIsOpen, setValidSMSModalIsOpen] = useState(false);
    const [parentPhone, setParentPhone] = useState('');
    const [loaderIsOpen, setLoaderIsOpen] = useState(false);


    const getLastPath = () => {
        switch(activeScreen){
            case "ContentButtons":
                return "content-buttons"
            case "Music":
                return "content-buttons/music"
            case "Tv":
                return "content-buttons/tv"
            case "Book":
                return "content-buttons/book"
            case "Videos":
                return "content-buttons/videos"
            case "Games":
                return "content-buttons/games"
            case "MyAccount":
                return "my-account"
            case "Terms":
                return "terms"
            case "QA":
                return "qa"
            case "About":
                return "about"
            case "Contact":
                return "contact"
            case "Registration":
                return "registration"
            default: 
                return "content-buttons"
        }
    }

    useEffect(async() => {
        const userToken = localStorage.getItem('token');
        const childName = localStorage.getItem('childName')
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if(userInfo && userInfo.phone && userToken){
            setLoaderIsOpen(true)
            const detailsResp = childName ? await parentDetailsResp({phone: userInfo.phone}, userToken, setLoaderIsOpen)
                                          : await userDetailsResp({phone: userInfo.phone}, userToken, setLoaderIsOpen)
            
            console.log('detailsResp:',detailsResp)
            const lastPath = getLastPath();
            console.log('lastPath:',lastPath)
            console.log('location:',location)
            console.log("activeScreen>>>:", activeScreen)
            if(detailsResp && !detailsResp.code){ // if user is already connected!
                // dispatch({ type: "SET_ACTIVE_SCREEN", value: "ContentButtons" });
                dispatch({ type: "UPDATE_USER_INFO", value: userInfo }) //update user info 
                dispatch({ type: "SET_USER_TOKEN", value: userToken }) //save user token
                dispatch({ type: "SET_IS_CONNECTED", value: true })
                
                childName && dispatch({ type: "SET_CHILD_NAME", value: childName }) //save child name
                history.push(lastPath);
            }
            // else if(detailsResp && detailsResp.error === 'INVALID_TOKEN'){
            //     localStorage.clear();
            // }
            else{
                dispatch({ type: "SET_IS_CONNECTED", value: false })
                localStorage.clear();
            }
            setLoaderIsOpen(false)
        }
    },[])

    
    useEffect(() => {
        activeScreen !== 'MainScreen' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'MainScreen' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])

    const handleLogClick = () => {
        // dispatch({ type: "SET_IS_CHILD", value: true})
        setAddUserModalIsOpen(true);
    }

    const handleRegClick = () => {
        // dispatch({ type: "SET_IS_CHILD", value: false})
        dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Registration' });
    }

    return (
        <div className={style.container}>
            {/* <button onClick={() => setLoaderIsOpen(true)}>open loader modal</button> */}
            <div className={style.loaderModal}>
                <MainScreenLoader modalIsOpen={loaderIsOpen} />
            </div>
            <div className={style.bgGarden}>
                <img
                    effect="blur"
                    alt={""}
                    src={garden}
                    width="100%"
                    height="100%"
                    draggable={false}
                />
            </div>
            <div className={style.balloonCloudsBg}>
                <img
                    effect="blur"
                    alt={""}
                    src={balloon_clouds}
                    width="100%"
                    height="100%"
                    draggable={false}
                />
            </div>
            <div className={style.contentBtnsGiftsBg}>
                <img
                    effect="blur"
                    alt={""}
                    src={contentBtns_gifts}
                    width="100%"
                    height="100%"
                    draggable={false}
                />
            </div>
            <div className={style.bodyContainer}>
                <div className={style.description}>
                    <div className={style.descTitle}>
                        עולם של מתנות קטנות
                    </div>
                    <div className={style.descContent}>
                        ברוכים הבאים לעולם המופלא של
                        <br />
                        <b>מתנות קטנות</b>
                        <br />
                        <br />
                        כאן תקבלו גישה לכל התכנים!
                        <br />
                        תוכלו לצפות בסדרה ובקליפים בהשתתפות הכוכבים הגדולים,
                        <br />
                        לקרוא את הספר, להאזין לשירים, לשחק וליצור.
                        <br />
                        <br />
                        תיהנו!
                    </div>
                </div>
                <div className={style.instruction}>
                    <div className={style.instTitle}>
                        ?איך נכנסים לאתר
                    </div>
                    <div className={style.instFormBtns}>
                        <div className={style.instFormBtn}>
                            <div className={style.title}>כניסה ממכשיר נוסף</div>
                            <div className={style.content}>נרשמתי בעבר, ואני רוצה להתחבר ממכשיר נוסף</div>
                            <div id={style.btn} onClick={handleLogClick}>התחברות</div>
                        </div>
                        <div className={style.instFormBtn}>
                            <div className={style.title}>כניסה בפעם הראשונה</div>
                            <div className={style.content}>יש לי קוד גישה, ואני רוצה להיכנס בפעם הראשונה</div>
                            <Link to='/registration' onClick={handleRegClick}>
                                <div id={style.btn}>
                                    הרשמה
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={style.videoPlayer}>
                    {/* <ReactPlayer width="100%" height="55vh"
                        url='https://matanotk.s3.eu-central-1.amazonaws.com/generated/Turtle.m3u8'
                        // url='https://matanotk.s3.eu-central-1.amazonaws.com/videos/Turtle.mp4'
                        controls={true}
                        style={{border: '.15vw solid #000', borderRadius: '1vw',
                                overflow: 'hidden', width:"100%" ,cursor: 'pointer',
                                backgroundColor: 'rgba(0,0,0,.8)'}}/> */}

                    <ReactPlayer width="100%" height="55vh"
                            url="https://matanotk.s3.eu-central-1.amazonaws.com/clips/entry/entry.m3u8"
                            controls={true}
                            autoPlay={true}
                            onStart={() => {console.log("onstart!!!!")}}
                            // style={{border: '.15vw solid #FFF', borderRadius: '1vw',
                            //         overflow: 'hidden', width:"100%" ,cursor: 'pointer',
                            //         backgroundColor: 'rgba(0,0,0,.8)'}}
                            loop={true}
                            // light={true}
                            // playIcon={<img width="100%" height="100%" alt="" src={require(`../../../assets/bg/tv-bg/${video.id}.jpg`).default} />}
                            style={reactPlayerStyle}
                        />
                    {/* <div className={style.bigappsLogo}>
                        <img width="100%" alt="" src={bigappsLogo} />
                    </div> */}
                    <div className={style.title}>?מה בפנים</div>
                </div>
                <div className={style.qaForm}>
                    <QAForm />
                </div>
            </div>
            <div className={style.modals}>
                <AddUser
                modalIsOpen={addUserModalIsOpen}
                setModalIsOpen={setAddUserModalIsOpen}
                setValidSMSModalIsOpen={setValidSMSModalIsOpen}
                setParentPhone={setParentPhone}
                />
                <ValidSMS
                modalIsOpen={validSMSModalIsOpen}
                setModalIsOpen={setValidSMSModalIsOpen}
                setAddUserModalIsOpen={setAddUserModalIsOpen}
                parentPhone={parentPhone}
                />
            </div>
        </div>
    )
}

export default MainScreen
