
const initState = {
    headerColor: 'yellow',
    userInfo: {
        name: '',
        phone: '',
        email: '',
        address: '',
        updates: '0'
    },
    activeScreen: "MainScreen",
    // kitToken: '',
    userToken: '',
    // userConnected: false,
    childName: '',
    // mathResult: null,
    successMobileReg: false,
    isConnected: false

}
const rootReducer = (state = initState, action) => {
    switch (action.type) {
        case "CHANGE_BG_COLOR": //header bg color
          return { ...state, headerColor: action.value };
        case "UPDATE_USER_INFO":
            return {...state, userInfo: action.value}
        case "SET_ACTIVE_SCREEN":
            return { ...state, activeScreen: action.value };
        case "SET_USER_TOKEN":
            return { ...state, userToken: action.value}
        case "SET_CHILD_NAME":
            return { ...state, childName: action.value }
        case "SET_SUCCESS_MOBILE_REG":
            return { ...state, successMobileReg: action.value }
        case "SET_IS_CONNECTED":
            return { ...state, isConnected: action.value }
        default:
          return state;
      }
}

export default rootReducer;