import React,{ useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.css';
import searchBtn from '../../assets/searchBtn.svg';
import { useHistory } from 'react-router-dom';

const answersStr = [`<div>המון דברים שילדים ישמחו לקבל!</div>
<div>- קוד גישה לאפליקציה ולאתר של ״מתנות קטנות״ לשימוש ב-4 מכשירים שונים</div>
<div>- ספר הילדים "מתנות קטנות - שירים לקטנים ולגדולים"</div>
<div>- חוברת צביעה והפעלה</div>
<div>- סט טושים</div>
<div>- קלמר מיוחד לצביעה</div>
<div>- סט מדבקות רב-פעמיות</div>
<div>- שבלונה ליצירה</div>
<div>- מודל פסנתר כנף להרכבה ולצביעה</div>
<div>- פחית שתיל לגידול בבית</div>
<div>- שלט לחדר (להכנה ע"י הילדים)</div>`,
`<>
<div>אפשר להזמין את המארז דרך החנות האינטרנטית בלבד:</div>
<div onClick={() => window.open('https://matanotk-shop.co.il')} style={{cursor: 'pointer', color: "#5DC3FF"}}>https://matanotk-shop.co.il</div>
</>`,
`<div>- סדרת ילדים בת 12 פרקים עם כל הכוכבים הגדולים</div>
<div>- כל שירי האלבום להאזנה</div>
<div>- האזנה לשירי האלבום</div>
<div>- קליפים בהשתתפות הכוכבים הגדולים</div>
<div>- ספר הילדים "מתנות קטנות - שירים לקטנים ולגדולים" בגרסה דיגיטלית</div>
<div>- פינת משחקים ויצירה</div>
<div>- ו... אנחנו – שנשמח להיות בקשר בכל עניין!</div>`,
`<div>ההמלצה אומרת 3-8,</div>
<div>אנחנו מאמינים 3-120.</div>`,
`<div>לאחר ביצוע הזמנה תקבל/י מייל אישור אוטומטי שההזמנה התקבלה אצלנו.</div>
<div></div>
<div>כשההזמנה נקלטת אצל חברת השליחויות יגיע סמס לנייד עם מס' למעקב.</div>
<div>ביום המסירה השליח יתקשר כשהוא נמצא אצלכם.</div>
<div>בקיצור, אנחנו מנדנדים עד שההזמנה אצלכם. רק בבקשה תזינו מייל וטלפון נכונים. 😊</div>`,
`<div>5-10 ימי עסקים.</div>
<div>אנחנו מבטיחים שאנחנו עושים את המקסימום כדי שזה יקרה במינימום זמן המתנה!</div>`,
`<div>אל דאגה, הקוד הפרטי שלך מודפס על גבי העמוד הראשון של ספר הילדים שנמצא בתוך המארז.</div>
<div>אנחנו רוצים שהקוד יישאר אצלך תמיד, כמו הספר. 😊</div>`,
`        <div>נכנסים לאתר <span onClick={() => window.open('https://matanotk.co.il')} style={{cursor: 'pointer', color: "#5DC3FF"}}>www.matanotk.co.il</span> או סורקים את הברקוד שנמצא על גבי המארז כדי</div>
<div>להוריד את האפליקציה למכשירכם.</div>
<br />
<div>במסך הבית תגלו שני לחצנים:</div>
<div>א. <b>הרשמה וכניסה</b> – יש לי קוד, ואני רוצה להיכנס לעולם התוכן בפעם הראשונה.</div>
<div>מזינים את הקוד האישי שלכם (שנמצא בעמוד הראשון של ספר הילדים) ומתחברים.</div>
<br />
<div>ב. <b>התחברות</b> – יש לי קוד, ואני רוצה להתחבר לעולם התוכן ממכשיר נוסף.</div>
<div>במקרה זה תזינו את מספר הטלפון איתו נרשמתם בפעם הראשונה, תקבלו הודעת אימות,</div>
<div>רק כדי לוודא שאלה אכן את. 😊</div>
<div>שימו לב, ניתן לצרף עד ארבעה מכשירים שונים סה"כ.</div>`,
`<div>ניתן להתחבר מכל מכשיר (מחשב, אייפון, אנדרואיד, טאבלט, אייפד), ועד 4 מכשירים למשפחה.</div>`,
`<div>בחנות המתנות הקסומה של <b>רמי קלינשטיין</b> מתארחים אמנים מהשורה הראשונה:</div>
<div>אליעד נחום, הראל סקעת, טלי אורן, יובל המבולבל, ישראל קטורזה, להקת שלווה, לי בירן,</div>
<div>מירי מסיקה, משה פרץ, ציפי שביט, רוני דלומי ושירי מימון.</div>`,
`<div>לא. רק מי שמחזיק בערכה וקיבל קוד אישי יכול לצפות בכל התכנים.</div>`,
`<div>נשמח לעמוד לרשותכם בכל עת בשעות הפעילות (א'-ה', 9:00-17:00)</div>
<div>מוזמנים לפנות אלינו במייל: <a href="mailto: info@matanotk.co.il" style={{cursor: 'pointer', color: "#5DC3FF"}}>info@matanotk.co.il</a></div>`,
`<div>איזה כיף! אנחנו נשמח.</div>
<div>מוזמנים לעקוב אחרינו בעמודי הפייסבוק, האינסטגרם והטיקטוק:</div>
<div>matanot.ktanot.kids@</div>
<div>להעלות תמונות או סרטונים ולתייג אותנו!</div>`
]

const a = (
    <>
        <div>המון דברים שילדים ישמחו לקבל!</div>
        <div>- קוד גישה לאפליקציה ולאתר של ״מתנות קטנות״ לשימוש ב-4 מכשירים שונים</div>
        <div>- ספר הילדים "מתנות קטנות - שירים לקטנים ולגדולים"</div>
        <div>- חוברת צביעה והפעלה</div>
        <div>- סט טושים</div>
        <div>- קלמר מיוחד לצביעה</div>
        <div>- סט מדבקות רב-פעמיות</div>
        <div>- שבלונה ליצירה</div>
        <div>- מודל פסנתר כנף להרכבה ולצביעה</div>
        <div>- פחית שתיל לגידול בבית</div>
        <div>- שלט לחדר (להכנה ע"י הילדים)</div>
    </>
)

const b = (
    <>
        <div>אפשר להזמין את המארז דרך החנות האינטרנטית בלבד:</div>
        <div onClick={() => window.open('https://matanotk-shop.co.il')} style={{cursor: 'pointer', color: "#5DC3FF"}}>https://matanotk-shop.co.il</div>
    </>
)

const c = (
    <>
        <div>- סדרת ילדים בת 12 פרקים עם כל הכוכבים הגדולים</div>
        <div>- כל שירי האלבום להאזנה</div>
        <div>- האזנה לשירי האלבום</div>
        <div>- קליפים בהשתתפות הכוכבים הגדולים</div>
        <div>- ספר הילדים "מתנות קטנות - שירים לקטנים ולגדולים" בגרסה דיגיטלית</div>
        <div>- פינת משחקים ויצירה</div>
        <div>- ו... אנחנו – שנשמח להיות בקשר בכל עניין!</div>
    </>
)

const d = (
    <>
        <div>ההמלצה אומרת 3-8,</div>
        <div>אנחנו מאמינים 3-120.</div>
    </>
)

const e = (
    <>
        <div>לאחר ביצוע הזמנה תקבל/י מייל אישור אוטומטי שההזמנה התקבלה אצלנו.</div>
        <div></div>
        <div>כשההזמנה נקלטת אצל חברת השליחויות יגיע סמס לנייד עם מס' למעקב.</div>
        <div>ביום המסירה השליח יתקשר כשהוא נמצא אצלכם.</div>
        <div>בקיצור, אנחנו מנדנדים עד שההזמנה אצלכם. רק בבקשה תזינו מייל וטלפון נכונים. 😊</div>
    </>
)

const f = (
    <>
        <div>5-10 ימי עסקים.</div>
        <div>אנחנו מבטיחים שאנחנו עושים את המקסימום כדי שזה יקרה במינימום זמן המתנה!</div>
    </>
)

const g = (
    <>
        <div>אל דאגה, הקוד הפרטי שלך מודפס על גבי העמוד הראשון של ספר הילדים שנמצא בתוך המארז.</div>
        <div>אנחנו רוצים שהקוד יישאר אצלך תמיד, כמו הספר. 😊</div>
    </>
)

const h = (
    <>
        <div>נכנסים לאתר <span onClick={() => window.open('https://matanotk.co.il')} style={{cursor: 'pointer', color: "#5DC3FF"}}>www.matanotk.co.il</span> או סורקים את הברקוד שנמצא על גבי המארז כדי</div>
        <div>להוריד את האפליקציה למכשירכם.</div>
        <br />
        <div>במסך הבית תגלו שני לחצנים:</div>
        <div>א. <b>הרשמה וכניסה</b> – יש לי קוד, ואני רוצה להיכנס לעולם התוכן בפעם הראשונה.</div>
        <div>מזינים את הקוד האישי שלכם (שנמצא בעמוד הראשון של ספר הילדים) ומתחברים.</div>
        <br />
        <div>ב. <b>התחברות</b> – יש לי קוד, ואני רוצה להתחבר לעולם התוכן ממכשיר נוסף.</div>
        <div>במקרה זה תזינו את מספר הטלפון איתו נרשמתם בפעם הראשונה, תקבלו הודעת אימות,</div>
        <div>רק כדי לוודא שאלה אכן אתם. 😊</div>
        <div>שימו לב, ניתן לצרף עד ארבעה מכשירים שונים סה"כ.</div>
    </>
)

      
const i = (
    <>
        <div>ניתן להתחבר מכל מכשיר (מחשב, אייפון, אנדרואיד, טאבלט, אייפד), ועד 4 מכשירים למשפחה.</div>
    </>
)
const j = (
    <>
        <div>בחנות המתנות הקסומה של <b>רמי קלינשטיין</b> מתארחים אמנים מהשורה הראשונה:</div>
        <div>אליעד נחום, הראל סקעת, טלי אורן, יובל המבולבל, ישראל קטורזה, להקת שלווה, לי בירן,</div>
        <div>מירי מסיקה, משה פרץ, ציפי שביט, רוני דלומי ושירי מימון.</div>
    </>
)

const k = (
    <>
        <div>לא. רק מי שמחזיק בערכה וקיבל קוד אישי יכול לצפות בכל התכנים.</div>
    </>
)

const l = (
    <>
        <div>נשמח לעמוד לרשותכם בכל עת בשעות הפעילות (א'-ה', 9:00-17:00)</div>
        <div>מוזמנים לפנות אלינו במייל: <a href="mailto: info@matanotk.co.il" style={{cursor: 'pointer', color: "#5DC3FF"}}>info@matanotk.co.il</a></div>
    </>
)

const m = (
    <>
        <div>איזה כיף! אנחנו נשמח.</div>
        <div>מוזמנים לעקוב אחרינו בעמודי הפייסבוק, האינסטגרם והטיקטוק:</div>
        <div>matanot.ktanot.kids@</div>
        <div>להעלות תמונות או סרטונים ולתייג אותנו!</div>
    </>
)

export const faqList = [
  {'מה כולל מארז "מתנות קטנות"?': a},
  {'איפה משיגים את המארז?': b},
  {'מה מחכה לכם באפליקציה ובאתר של "מתנות קטנות"': c},
  {'לאיזה גילאים מתאים התוכן ב"מתנות קטנות"?': d},
  {'ביצעתי הזמנה. מה עכשיו?': e},
  {'מה משך האספקה?': f},
  {'קניתי את המארז ואני לא מוצא/ת את הקוד!!': g},
  {'איך מתחברים?': h},
  {'מכמה ומאילו מכשירים אפשר להיכנס לאתר ולאפליקציה של "מתנות קטנות"?': i},
  {'אילו כוכבים מתארחים בסדרת הטלוויזיה "מתנות קטנות"?': j},
  {'האם כל אחד יכול להיחשף לעולם מתנות קטנות?': k},
  {'כיצד ניתן ליצור קשר או לפנות לשירות לקוחות?': l},
  {'בא לי לשתף בחוויה – מה עושים?': m},
];

const QA = () => {
    const history = useHistory();
    const dispatch = useDispatch(); 
    const activeScreen = useSelector((state) => state.activeScreen);
    const headerColor = useSelector((state) => state.headerColor);
    const [textInput, setTextInput] = useState('');
    const [faqsSelected, setFaqsSelected] = useState(faqList);
    const inputRef = useRef(null);

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'QA' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'QA' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    },[])

    useEffect(() => {
        if(inputRef && inputRef.current){
            inputRef.current.focus();
        }
    })

    const handleTextChange = e => {
        if(!e.target.value){
            setTextInput('')
            setFaqsSelected(faqList);
        }
        else{
            setTextInput(e.target.value)
        }
    }

    const handleTextSubmit = e => {
        e.preventDefault();
        if(!textInput) return;
        
        const newFaqs = [];
        
        faqList.forEach((faq, idx) => {
            if(Object.keys(faq)[0].indexOf(textInput) >= 0 || answersStr[idx].indexOf(textInput) >= 0){
                newFaqs.push(faq);
            }

        })

        setFaqsSelected(newFaqs)
    }

    return (
        <div className={style.container}>
            <form onSubmit={handleTextSubmit}>
                <div className={style.searchField}>
                    <input type='text' ref={inputRef} onChange={handleTextChange} placeholder='חיפוש שאלה' />
                    <button type='submit'>
                        <div className={style.searchImg}>
                            <img src={searchBtn} width='100%' alt='' onClick={handleTextSubmit} />
                        </div>
                    </button>
                </div>
            </form>
            <div className={style.items}>
                {
                    faqsSelected.length
                    ?
                        faqsSelected.map((faq, idx) => {
                            return(
                                <div className={style.item} key={idx}>
                                    <div className={style.question}>{Object.keys(faq)[0]}</div>
                                    <div className={style.answer}>{Object.values(faq)[0]}</div>
                                </div>
                            )
                        })
                    :
                        <div className={style.searchFaild}>
                            <div className={style.searchFaildMsg}>לא נמצאו תוצאות!</div>
                            <div className={style.searchFaildSubMsg}>אנא הקלד/י טקסט אחר בשדה חיפוש.</div>
                        </div>
                }
            </div>
        </div>
    )
}

export default QA
