import React, { useEffect, useState } from 'react'
import style from './style.module.css';
import rami from '../../assets/about-imgs/rami.png';
import noam from '../../assets/about-imgs/noam.png';
import matanot from '../../assets/about-imgs/matanot.png';
import black_border from '../../assets/about-imgs/black_border.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const About = () => {
    const history = useHistory();
    const [imgSelected, setImgSelected] = useState('matanot')
    const dispatch = useDispatch(); 
    const activeScreen = useSelector((state) => state.activeScreen);
    const headerColor = useSelector((state) => state.headerColor);
    
    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'About' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'About' })
    },[activeScreen])
    
    useEffect(() => {
        headerColor !== 'yellow' && dispatch({ type: "CHANGE_BG_COLOR", value: 'yellow' })
    },[headerColor])

    const getContent = () => {
        switch(imgSelected){
            case 'noam':
                return(
                    <>
                        <div>כותב ויוצר, זוכה פרסי אקו"ם, מהבולטים בעשור האחרון.</div>
                        <div>חתום על הלהיטים הגדולים למיטב אמני ישראל.</div>
                        <div>מחבר רבי-המכר "טיוטה של אושר", "לציונה יש כנף אחת" ו"טביעת האצבע של איל".</div>
                        <br />
                        <div>כתב את כל שירי האלבום, את הספר ואת סדרת הילדים.</div>
                    </>
                )
            case 'rami':
                return(
                    <>
                        <div>זמר, יוצר ומלחין, מהמובילים בארץ. בשלושים השנים האחרונות כתב והלחין, </div>
                        <div>לעצמו ולאחרים, עשרות קלסיקות ישראליות שפורטות על מיתרי הלב של כל אחד ואחת מאיתנו.</div>
                        <br />
                        <div>המלחין, המבצע והמנהל המוזיקלי של כל שירי הפרויקט, והשחקן הראשי </div>
                        <div>בסדרת הילדים – לצד פיליס, פרח, סרט ואורחים מיוחדים.</div>
                    </>
                )
            default:
                return(
                    <>
                        <div>פרויקט ישראלי איכותי לשכבת הגיל הצעירה (3-8), מבית היוצר של רמי קלינשטיין ונעם חורב.</div>
                        <div>עולם תוכן שלם הכולל: 12 שירים מקוריים וחדשים – לקטנים ולגדולים, ספר ילדים, סדרה  </div>
                        <div>בהשתתפות כל הכוכבים הגדולים, אתר ואפליקציה חווייתיים ומארז </div>
                        <div>שכולו מתנות קטנות לילדים שאוהבים ליצור.</div>
                    </>
                )
        }
    }

    const getTitle = () => {
        switch(imgSelected){
            case "rami":
                return 'רמי קלינשטיין'
            case "noam":
                return 'נעם חורב'
            default:
                return 'מתנות קטנות - שירים לקטנים ולגדולים'
        }
    }

    const renderImgsOrder = () => {
        let ordered = null;

        const ramiImg = 
            (
                <div className={style.image} onClick={() => setImgSelected('rami')}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={rami}
                    width="100%"
                    draggable={false}
                    />
                </div>
            )

        const matanotImg =
            (
                <div className={style.image} onClick={() => setImgSelected('matanot')}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={matanot}
                    width="100%"
                    draggable={false}
                    />
                </div>   
            )

        const noamImg =
            (
                <div className={style.image} onClick={() => setImgSelected('noam')}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={noam}
                    width="100%"
                    draggable={false}
                    />
                </div>
            )

        switch(imgSelected){
            case 'noam':
                ordered =
                    <>
                        {ramiImg}
                        {noamImg}
                        {matanotImg}
                    </>
                break;
            case 'rami':
                ordered =
                    <>
                        {matanotImg}
                        {ramiImg}
                        {noamImg}
                    </>
                break;
            default:
                ordered =
                    <>
                        {ramiImg}
                        {matanotImg}
                        {noamImg}
                    </>
                break;
        }

        return ordered;
    }

    return (
        <div className={style.container}>
            <div className={style.images}>
                { renderImgsOrder() }
            </div>
            <div className={style.description}>
                <div className={style.border}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={black_border}
                    width="100%"
                    draggable={false}
                    />

                    <div className={style.aboutText}>
                        <div className={style.title}>{getTitle()}</div>
                        <div className={style.content}>
                            {getContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
