import React, { useState } from "react";
import style from "./style.module.css";
import blueBG from "../../assets/headerPanels/blue.png";
import greenBG from "../../assets/headerPanels/green.png";
import yellowBG from "../../assets/headerPanels/yellow.png";
import orangeBG from "../../assets/headerPanels/orange.png";
import pinkBG from "../../assets/headerPanels/pink.png";
import bPinkBG from "../../assets/headerPanels/bright_pink.png";
import mainLogo from "../../assets/logo/mainLogo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import Login from "../modals/Login";
import ValidSMS from "../modals/ValidSMS";
import AddUser from "../modals/AddUser";
import homeBtn from "../../assets/homeBtn.svg";
import { useDispatch, useSelector } from "react-redux";
import arrow from "../../assets/arrow.svg";
import arrowCircle from "../../assets/arrowCircle.svg";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const [loginModalIsOpen, setLoginModalIsOpen] = useState(false);
  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [validSMSModalIsOpen, setValidSMSModalIsOpen] = useState(false);
  const [phoneLogin, setPhoneLogin] = useState('');
  const history = useHistory();

  const dispatch = useDispatch(); 
  const headerColor = useSelector((state) => state.headerColor);
  const userInfo = useSelector((state) => state.userInfo);
  const activeScreen = useSelector((state) => state.activeScreen);
  const isConnected = useSelector((state) => state.isConnected);
  const userToken = useSelector((state) => state.userToken);
  const isMobile = useMediaQuery({ query: `(max-width: 580px)` });

  const getScreenName = () => {
    switch(activeScreen){
      case 'Tv':
        return 'סדרה';
      case 'Music':
        return 'שירים';
      case 'Videos':
        return 'קליפים';
      case 'Games':
        return 'משחקים ויצירה';
      case 'Book':
        return 'ספר';
      case 'Registration':
        return 'הרשמה';
      case 'KitCode':
        return 'התחברות';
      case 'ContentButtons':
        const childName = localStorage.getItem("childName");
        if(childName){
          return `היי ${childName}`;
        }
        return `היי ${userInfo.name}`;
      case 'MyAccount':
        return 'החשבון שלי';
      case 'QA':
        return 'שאלות נפוצות';
      case 'About':
        return 'אודותינו';
      case 'Contact':
        return 'יצירת קשר';
      case 'Terms':
        return 'תקנון';
      default:
        return null;
    }
  }

  const getBgColor = () => {
    switch (headerColor) {
      case "yellow":
        return yellowBG;
      case "green":
        return greenBG;
      case "orange":
        return orangeBG;
      case "pink":
        return pinkBG;
      case "bPink":
        return bPinkBG;
      case "blue":
        return blueBG;
      default:
        return yellowBG;
    }
  };

  const showMyAccountScreens = ['Tv', 'Music', 'Videos', 'Games', 'Book', 'ContentButtons'];
  const footerScreens = ['QA', 'About', 'Contact', 'Terms'];
  const connectScreens = ['Registration', 'MainScreen', ''];

  return (
    
    <div className={style.container}>
        <div className={style.bgImage}>
            <LazyLoadImage
            effect="blur"
            alt={""}
            src={getBgColor()}
            height="100%"
            width="100%"
            draggable={false}
            />
        </div>

        <div className={style.wrappElements}>
          {
            isMobile
            ?
              null
            :
              <div className={style.leftBtn}>
                  {/*
                      activeScreen === 'ContentButtons' || activeScreen === 'Music' || activeScreen === 'Book' 
                      || activeScreen === 'Games' || activeScreen === 'Tv' || activeScreen === "Videos"
                      ? 
                        <div className={style.button} onClick={() => history.push('/my-account')}>
                          החשבון שלי
                        </div>
                      :
                        !localStorage.getItem('token') || !localStorage.getItem('userInfo') 
                          || !localStorage.getItem('userInfo').length
                        ?
                          activeScreen === 'Terms' || activeScreen === 'QA'
                          || activeScreen === 'Contact' || activeScreen === 'About'
                          ?
                            <div className={style.button} onClick={() => history.push('/')}>
                              כניסה/הרשמה
                            </div>
                          :
                            activeScreen !== "KitCode"
                            ?
                              <div className={style.button} onClick={() => setLoginModalIsOpen(true)}>
                                התחברות
                              </div>
                            :
                              null
                        :
                          activeScreen === 'Terms' || activeScreen === 'QA'
                            || activeScreen === 'Contact' || activeScreen === 'About'
                          ?
                            <div className={style.leftHomeBtn} onClick={() => history.push("/content-buttons")}>
                              <img src={homeBtn} width="100%" />
                            </div>
                          :
                            null*/
                      // check if the user is connected
                      isConnected === true
                      ?
                        //  the user is connected
                        //  check if the active screen is one of the accounts
                        showMyAccountScreens.includes(activeScreen)
                        ?
                          //  show the my account button
                          <div className={style.button} onClick={() => history.push('/my-account')}>
                            החשבון שלי
                          </div>
                        :
                          //  check if the active screen is one of the footers
                          footerScreens.includes(activeScreen)
                          ?
                            //  show the home image
                            <div className={style.leftHomeBtn} onClick={() => history.push("/content-buttons")}>
                              <img src={homeBtn} width="100%" alt=""/>
                            </div>
                          :
                            //  in my account screen, show nothing
                            null
                      :
                        //  user is not connected
                        //  check if the active screen is one of the footers
                        footerScreens.includes(activeScreen)
                        ?
                          //  show the registration button
                          <div className={style.button} onClick={() => history.push('/')}>
                            כניסה/הרשמה
                          </div>
                        :
                          //  check if the screen is registration or main screen
                          connectScreens.includes(activeScreen)
                          ?
                            // show connect button
                            <div className={style.button} onClick={() => setLoginModalIsOpen(true)}>
                                התחברות
                              </div>
                          :
                            null

              }
              </div>
          }

            <div className={style.mainLogo}>
                    <LazyLoadImage
                        effect="blur"
                        src={mainLogo}
                        width="85%"
                        draggable={false}
                        style={{ marginLeft: '25%'}}
                    />
            </div>
        {
          isMobile
          ?
            null
          :
            <div className={style.wrapRightBtn}>
            {
              activeScreen === 'ContentButtons'
              ?
                <div className={style.cbName}>{getScreenName()}</div>
              :
                activeScreen === 'Music' || activeScreen === 'Book' || activeScreen === 'Games'
                  || activeScreen === 'Tv' || activeScreen === "Videos"
                ?
                    <div className={style.tripleBtn}>
                      <Link to='/content-buttons' style={{width: '2.5vw'}}>
                          <div className={style.homeBtn}><img src={homeBtn} width="100%" /></div>
                      </Link>
                      <div className={style.leftArrow} onClick={history.goBack}><img src={arrow} alt="" width="100%" /></div>
                      <div className={style.name} style={{fontSize: activeScreen === 'Games' ? '1.2vw' : null }}>{getScreenName()}</div>
                    </div>
                :
                  activeScreen === 'MainScreen'
                  ?
                    null
                  :
                    <div className={style.button} onClick={() => window.history.back()}>
                        <div className={style.circleArrowImg}>
                          <LazyLoadImage
                                effect="blur"
                                src={arrowCircle}
                                width="100%"
                                draggable={false}
                                // style={{position: 'absolute', top: '-3vh', right: '.2vw'}}
                            />
                        </div>
                        <div className={style.btnName}>{getScreenName()}</div>
                    </div>
            }

            </div>
        }
      </div>
      <div className={style.modals}>
        <Login modalIsOpen={loginModalIsOpen}
          setModalIsOpen={setLoginModalIsOpen}
          setValidSMSModalIsOpen={setValidSMSModalIsOpen}
          setPhoneLogin={setPhoneLogin}
        />
        <AddUser
          modalIsOpen={addUserModalIsOpen}
          setModalIsOpen={setAddUserModalIsOpen}
          setValidSMSModalIsOpen={setValidSMSModalIsOpen}
        />
        <ValidSMS
          modalIsOpen={validSMSModalIsOpen}
          setModalIsOpen={setValidSMSModalIsOpen}
          setLoginModalIsOpen={setLoginModalIsOpen}
          isLoginProcess={true}
          phoneLogin={phoneLogin}
        />
      </div>
    </div>
  );
};

export default Header;
