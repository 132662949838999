import React from 'react'
import style from './style.module.css'
import ReactCardFlip from 'react-card-flip';

const Card = ({ card, flip, secondFlipped, cardsAmount}) => {

    const requestCardImage = require.context('../../../../../assets/games-imgs/zikaron', true, /.png$/);

    const handleClick = e => {
        e.preventDefault();
        if(!card.isFlipped && !card.hasMatch && !secondFlipped){
            flip(card);
        }
      }
    return (
        <div className={style.card}>
            <ReactCardFlip isFlipped={card.isFlipped}>
                <div className={style.front}>
                    <img alt='' width="100%"
                        src={requestCardImage(`./zicaron-cards_${25}.png`).default}
                        onClick={handleClick}
                    />
                </div>
                <div className={style.back}>
                    <img alt='' width="100%"
                        src={requestCardImage(`./zicaron-cards_${card.imgId}.png`).default}
                        onClick={handleClick}
                    />
                </div>
            </ReactCardFlip>
        </div>
    )
}

export default Card
