import React, {useEffect} from 'react';
import style from './style.module.css';
// import gameController from '../../../assets/gameController.svg'
import drawingBg from '../../../assets/games-imgs/drawing.jpg'
import hatamotBg from '../../../assets/games-imgs/hatamot.jpg'
import zikaronBg from '../../../assets/games-imgs/zikaron.jpg'
import { useDispatch, useSelector} from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Games = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Games' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Games' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'orange' && dispatch({ type: "CHANGE_BG_COLOR", value: 'orange' })
    },[headerColor])


    const imgStyle = {
        borderRadius: "2vw",
        boxShadow: "0vw .1vw .7vh rgba(0,0,0,.3)",
    }

    return (
        <div className={style.container}>
            <div className={style.windows}>
                <Link to='/content-buttons/games/zikaron' style={{width: '100%'}}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={zikaronBg}
                        width="100%"
                        draggable={false}
                        style={imgStyle}
                    />
                </Link>
                <Link to='/content-buttons/games/hatamot' style={{ width: '100%'}} >
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={hatamotBg}
                        width="100%"
                        draggable={false}
                        style={imgStyle}
                    />
                </Link>
                <Link to='/content-buttons/games/drawing' style={{width: '100%'}}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={drawingBg}
                        width="100%"
                        draggable={false}
                        style={imgStyle}
                    />
                </Link>
            </div>
        </div>
    )
}

export default Games
