import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import { Link } from 'react-router-dom';
import logo from '../../../assets/logo/mainLogo.png';
import closeBtn from '../../../assets/closeBtn.svg';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import smsValidBg from '../../../assets/bg/smsValidBg.png';
import { userDetailsResp, verifyCodeResp } from '../../../api/api';
import { useFormik } from 'formik';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import PulseLoader from "react-spinners/PulseLoader";
import useLocalStorage from '../../../hooks/useLocalStorage';

const ValidSMS = ({ modalIsOpen, setModalIsOpen, setAddUserModalIsOpen,
                    isLoginProcess, phoneLogin, setLoginModalIsOpen, parentPhone}) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const activeScreen = useSelector((state) => state.activeScreen);
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lSUserInfo, setLSUserInfo] = useLocalStorage('userInfo', {})
    const isMobile = useMediaQuery({ query: `(max-width: 580px)` });

    const onSubmit = async values => {
        errorMsg && setErrorMsg('')
        setIsLoading(true);

        //make data object
        let data = {};
        if(isLoginProcess){
            data = {
                phone: phoneLogin,
                code: values.code,
                reason: 'parent_login'
            }
        }
        else if(activeScreen !== 'Registration'){ //data for adding child user
            data = {
                phone: parentPhone,
                code: values.code,
                reason: 'child_registration',
            }
        }
        else{
            data = {
                phone: userInfo.phone,
                code: values.code,
                reason: 'parent_registration',
            }
        }
        const resp = await verifyCodeResp(data);
        
        if(resp && !resp.code){
            if(isLoginProcess){ //login process
                const detailsResp = await userDetailsResp({phone: phoneLogin}, resp.token)
                if(detailsResp && !detailsResp.code){
                    dispatch({ type: "UPDATE_USER_INFO", value: detailsResp.data }) //update user info 
                    setLSUserInfo(detailsResp.data); // update localstorage - userInfo
                    localStorage.setItem('token', resp.token) // update localstorage - token
                    errorMsg && setErrorMsg('');

                    dispatch({ type: "SET_USER_TOKEN", value: resp.token }) //save user token
                    dispatch({ type: "SET_ACTIVE_SCREEN", value: 'ContentButtons' }) //update next screen 
                    console.log('detailsResp:',detailsResp)

                    setModalIsOpen(false)
                    history.push('/content-buttons')//next screen
                }
                else{
                    setErrorMsg('משהו השתבש! נסו שוב.')
                }
            }
            else{ //registration process
                errorMsg && setErrorMsg('');
                dispatch({ type: "SET_ACTIVE_SCREEN", value: 'KitCode' }) //update next screen 
                dispatch({ type: "SET_USER_TOKEN", value: resp.token }) //save user token
                history.push('/kit-code')//next screen
                
                setModalIsOpen(false)
            }

        }
        else if(resp && resp.code){
            setErrorMsg('קוד לא תקין! נסו שוב.')
        }
        else{
            setErrorMsg('משהו השתבש! נסו שוב.')
        }

        setIsLoading(false);
        console.log('verify-code resp:', resp)
    }

    const initialValues = {
        code: ''
    }

    const validate = values => {
        let errors = {};

        if(!values.code){
            errors.code = 'שדה ריק!'
        }

        return errors;
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    const phoneNotMine = () => {
        setModalIsOpen(false)
        if(isLoginProcess){
            setLoginModalIsOpen(true);
        }
        else if(activeScreen !== 'Registration'){
            setAddUserModalIsOpen(true);
        }
    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: '#afafaf',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: isMobile ? '80%' : '20%',
                        backgroundColor: '#f5f0db',
                        border: isMobile ?  '7px solid #fff' : '.3vw solid #FFF',
                        borderRadius: isMobile ? '7px' : '.3vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.bgImage}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={smsValidBg}
                    width="105%"
                    height="110%"
                    draggable={false}
                    />
                </div>
                <div className={style.closeBtn} onClick={() => setModalIsOpen(false)}>
                    <img src={closeBtn} alt="" width="100%"/>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className={style.logoBtn}>
                        <img src={logo} width='100%' height='100%' draggable={false} alt='' />
                    </div>
                    <div className={style.title}>מה הקוד שקיבלת?</div>
                    <div className={style.subtitle}>שלחנו קוד לאימות החשבון שלך לטלפון שהזנת:</div>
                    <div className={style.subtitle}>{userInfo.phone ? userInfo.phone : phoneLogin}</div>
                    <div className={style.wrongPhone} onClick={phoneNotMine}>אופסי, זה לא הטלפון שלי...</div>
                    <div className={style.codeInput}>
                        <label>הקוד שקיבלת לטלפון</label>
                        <input type='text' id='code' name='code'
                            placeholder='הקלד/י...' 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.code}
                            style={{border: formik.errors.code && formik.touched.code ? '.2vh solid #FF0000' : ''}}
                            />
                        {/* {formik.errors.code && formik.touched.code ? <div className={style.errorMsg}>{formik.errors.code}</div> : null} */}
                    </div>
                        <button type='submit' disabled={isLoading} className={style.btn} onClick={isLoading ? null : formik.handleSubmit}>אישור</button>
                        {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                        <div className={isLoading ? style.spinner : null}>
                            <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size={isMobile ? '18px' : '.8vw'} />
                        </div>
                </form>
            </div>
        </Modal>
    )
}

export default ValidSMS
