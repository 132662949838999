import axios from 'axios';

const my_env = {
    url: process.env.REACT_APP_MATANOTK_URL,
    token: process.env.REACT_APP_MATANOTK_TOKEN,
}

export const regParentResp = async (data) => {
    console.log('dataaaa',data)
    const { url, token } = my_env;
    const my_url = url + 'register-parent';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            // 'Authorization': `Basic ${token}`, 
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(_ => {})

    return resp;
}

export const verifyCodeResp = async (data) => {
    const { url, token } = my_env;
    const my_url = url + 'verify-code';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            // 'Authorization': `Basic ${token}`, 
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(_ => {});

    return resp;
}

export const verifyKitCodeResp = async (data, kitToken, setIsLoading, setErrorMsg) => {
    const { url } = my_env;
    const my_url = url + 'verify-kit';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Authorization': 'Bearer ' + kitToken, 
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(_ => {
            setIsLoading(false);
            setErrorMsg('משהו השתבש! נסו שוב.');
        })

    return resp;
}

export const loginParentResp = async (data) => {
    const { url } = my_env;
    const my_url = url + 'login-parent';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(_ => {})

    return resp;
}

export const userDetailsResp = async (data, userDetailsToken, setLoaderIsOpen) => { // for parent details
    const { url } = my_env;
    const my_url = url + 'user-details';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Authorization': 'Bearer ' + userDetailsToken, 
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(_ => {setLoaderIsOpen(false)})

    return resp;
}

export const parentDetailsResp = async (data, parentDetailsToken, setLoaderIsOpen) => { // for child details
    const { url } = my_env;
    const my_url = url + 'parent-details';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Authorization': 'Bearer ' + parentDetailsToken, 
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(_ => {setLoaderIsOpen(false)})

    return resp;
}

export const regChildResp = async (data, setIsLoading, setErrorMsg) => {
    const { url, token } = my_env;
    const my_url = url + 'register-child';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            // 'Authorization': `Basic ${token}`,
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(err => {
            console.log('err:', err)
            setIsLoading(false);
            setErrorMsg('משהו השתבש! נסו שוב.');
        })

    return resp;
}

export const updateUserResp = async (data,userToken, setIsLoading, setErrorMsg) => {
    const { url } = my_env;
    const my_url = url + 'update-user';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(err => {
            console.log('err:', err)
            setIsLoading(false);
            setErrorMsg('משהו השתבש! נסו שוב.');
        })

    return resp;
}

export const contactResp = async (data, setIsLoading, setErrorMsg) => {
    const { url, token } = my_env;
    const my_url = url + 'contact';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(err => {
            console.log('err:', err)
            setIsLoading(false);
            setErrorMsg('משהו השתבש! נסו שוב.');
        })

    return resp;
}

export const downloadPicsResp = async (data, userToken) => {
    const { url } = my_env;
    const my_url = url + 'download-pictures';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(err => {
            console.log('err:', err)
            // setIsLoading(false);
            // setErrorMsg('משהו השתבש! נסו שוב.');
        })

    return resp;
}


export const unregChildResp = async (data, userToken, setIsLoading, setErrorMsg) => {
    const { url } = my_env;
    const my_url = url + 'unregister-child';
    let resp = null;
    await axios({
        method: 'post',
        url: my_url,
        data,
        headers: {
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json',
        }
    }).then(response => resp = response.data)
        .catch(err => {
            console.log('err:', err)
            setIsLoading(false);
            setErrorMsg('משהו השתבש! נסו שוב.');
        })

    return resp;
}




export default my_env;


