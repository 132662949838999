import React from 'react'
import style from './style.module.css'
import logo from '../../assets/logo/mainLogo.png';
import bigappsLogo from '../../assets/logo/bigappsLogo.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Footer = () => {
    const dispatch = useDispatch(); 
    const activeScreen = useSelector((state) => state.activeScreen);
    
    const handleClick = (screenName) => {
        activeScreen !== screenName && dispatch({ type: "SET_ACTIVE_SCREEN", value: screenName })
    }
    return (
        <div className={style.container} 
                    style={{ position: activeScreen === "MainScreen" ? 'unset' : 'fixed'}}>
            <div className={style.links}>
                <Link to='/contact' onClick={() => handleClick('Contact')} style={{color: activeScreen === "Contact" ? '#FFF' : 'unset'}}><div className={style.link}>יצירת קשר</div></Link>
                <Link to='/about' onClick={() => handleClick('About')} style={{color: activeScreen === "About" ? '#FFF' : 'unset'}}><div className={style.link}>אודות</div></Link>
                <Link to='/qa' onClick={() => handleClick('QA')} style={{color: activeScreen === "QA" ? '#FFF' : 'unset'}}><div className={style.link}>שאלות נפוצות</div></Link>
                <Link to='/terms' onClick={() => handleClick('Terms')} style={{color: activeScreen === "Terms" ? '#FFF' : 'unset'}}><div className={style.link}>תקנון</div></Link>
            </div>
            <div className={style.icons}>
                <div className={style.bigappsIcon} onClick={() => window.open('https://bigapps.co.il/')}>
                    <img src={bigappsLogo} title='BiGapps' draggable={false} width='100%' alt='' />
                </div>
                <div className={style.icon}>
                    <img src={logo} title='מתנות קטנות' draggable={false} width='100%' alt='' />
                </div>
            </div>
        </div>
    )
}

export default Footer
