import React, { useEffect } from 'react';
import Footer from './Components/Footer';
import Header from './Components/Header';
import MainScreen from './Components/MainScreen';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Registration from './Components/Registration';
import Contact from './Components/Contact';
import About from './Components/About';
import QA from './Components/QA';
import Terms from './Components/Terms';
import Modal from 'react-modal';
import KitCode from './Components/KitCode';
import ContentButtons from './Components/ContentButtons';
import MyAccount from './Components/MyAccount';
import Book from './Components/ContentButtons/Book';
import Music from './Components/ContentButtons/Music';
import Tv from './Components/ContentButtons/Tv';
import Videos from './Components/ContentButtons/Videos';
import Games from './Components/ContentButtons/Games';
import Zikaron from './Components/ContentButtons/Games/Zikaron';
import ScrollIntoView from './hooks/ScrollIntoView';
// import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Mobile from './Components/Mobile';
import Drawing from './Components/ContentButtons/Games/Drawing';
import Hatamot from './Components/ContentButtons/Games/Hatamot';
import { useSelector } from 'react-redux';


Modal.setAppElement('#root'); //for Modal package wranning msg!
const App = () => {  
  const isMobile = useMediaQuery({ query: `(max-width: 580px)` });
  const activeScreen = useSelector((state) => state.activeScreen);
  const isConnected = useSelector((state) => state.isConnected);

  console.log("window.location:", window.location)
  // console.log("asdasdsadas",window.location.hash.toLowerCase().indexOf("#/registration"))

  // Prevent screens entering by change url!
  if(!isConnected && 
      (window.location.hash.toLocaleLowerCase().indexOf("content-buttons") >= 0
       || window.location.hash.toLocaleLowerCase().indexOf("my-account") >= 0)){
    window.location.href = '/' // routing to main screen
  }

  // Prevent screens entering by past url!
  useEffect(() => {
    if(window.location.hash.toLocaleLowerCase().indexOf("kit-code") >= 0 
        || window.location.hash.toLocaleLowerCase().indexOf("content-buttons") >= 0
        || window.location.hash.toLocaleLowerCase().indexOf("my-account") >= 0){
          console.log("-WRONG SCREEN-") 
          window.location.href = '/' // routing to main screen
    }
  }, [])
  

  //responsive screen allowed !
  if(isMobile && activeScreen !== "Terms" && activeScreen !== "Registration"
        && activeScreen !== "KitCode" && window.location.hash.toLocaleLowerCase().indexOf("registration") < 0 
          && window.location.hash.toLocaleLowerCase().indexOf("terms") < 0){
      
      console.log("-MOBILE-")
      return(
        <Mobile />
      )
  }
    
  return (
    <div className="App">
      <HashRouter>
        <ScrollIntoView>
          <Header />
          <Switch component={MainScreen}>
            <Route exact path='/'>
              <MainScreen />
            </Route>
            <Route path='/registration'>
              <Registration />
            </Route>
            {/* <Route exact path='/purchase'>
              <Purchase />
            </Route>
            <Route exact path='/purchase/payment'>
              <Payment />
            </Route>
            <Route path='/purchase/payment/new-code-successfuly'>
              <NewCodeMsg />
            </Route> */}
            <Route path='/contact'>
              <Contact />
            </Route>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/qa'>
              <QA />
            </Route>
            <Route path='/terms'>
              <Terms />
            </Route>
            <Route exact path='/kit-code'>
              <KitCode />
            </Route>
            <Route exact path='/content-buttons'>
              <ContentButtons />
            </Route>
            <Route exact path='/content-buttons/book'>
              <Book />
            </Route>
            <Route exact path='/content-buttons/games'>
              <Games />
            </Route>
            <Route exact path='/content-buttons/games/zikaron'>
              <Zikaron />
            </Route>
            <Route exact path='/content-buttons/games/drawing'>
              <Drawing />
            </Route>
            <Route exact path='/content-buttons/games/hatamot'>
              <Hatamot />
            </Route>
            <Route exact path='/content-buttons/Music'>
              <Music />
            </Route>
            <Route exact path='/content-buttons/tv'>
              <Tv />
            </Route>
            <Route exact path='/content-buttons/videos'>
              <Videos />
            </Route>
            <Route exact path='/my-account'>
              <MyAccount />
            </Route>
            <Route component={MainScreen}/>
          </Switch>
          { isMobile ? null : <Footer /> }
        </ScrollIntoView>
      </HashRouter>
    </div>
  );
}

export default App;
