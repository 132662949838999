import React, { useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux';
import style from './style.module.css';
import play from '../../../assets/redPlayBtn.svg'
import { videoList, getGuestFormat } from '../Tv'
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import { getPlayIcon } from '../Tv'

const Videos = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Videos' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Videos' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'pink' && dispatch({ type: "CHANGE_BG_COLOR", value: 'pink' })
    },[headerColor])


    const reactPlayerStyle = {
        overflow: "hidden",
        border: ".25vw solid #DF4B4B",
        // backgroundColor: "#F9F9F9",
        borderRadius: "2vw",
        // width: "100%",
        // height: "100%",
        backgroundColor: 'rgba(0,0,0,.2)',
        boxShadow: "0vw 0vw .8vh rgba(77,105,169,.2)"
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
    }

    return (
        <div>
            <div className={style.container}>
                <div className={style.videos}>
                {
                    videoList.map(video => {
                        return(
                            <div className={style.video} key={video.id}>
                                {/* <div className={style.window}>
                                    <img src={play} alt="" width='20%'/> */}
                                        <ReactPlayer width="100%" height="100%"
                                            url={`https://matanotk.s3.eu-central-1.amazonaws.com/clips/ep${video.id > 9 ? video.id : "0" + video.id}/ep${video.id > 9 ? video.id : "0" + video.id}.m3u8`}
                                            controls={true}
                                            autoPlay={false}
                                            onStart={() => {console.log("onstart!!!!")}}
                                            // style={{border: '.15vw solid #FFF', borderRadius: '1vw',
                                            //         overflow: 'hidden', width:"100%" ,cursor: 'pointer',
                                            //         backgroundColor: 'rgba(0,0,0,.8)'}}
                                            loop={false}
                                            light={true}
                                            // playIcon={<img width="100%" height="100%" alt="" src={require(`../../../assets/bg/tv-bg/${video.id}.jpg`).default} />}
                                            playIcon={getPlayIcon(video.id, play)}
                                            style={reactPlayerStyle}
                                        />
                                {/* </div> */}
                                <div className={style.details}>
                                    <div className={style.name}>פרק {video.id} - {video.name}</div>
                                    <div className={style.guest}>{getGuestFormat(video.guest, video.id)}</div>
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default Videos
