import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import logo from '../../../assets/logo/mainLogo.png';
import closeBtn from '../../../assets/closeBtn.svg';
import { useDispatch } from 'react-redux';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import smsValidBg from '../../../assets/bg/smsValidBg.png';
import { loginParentResp } from '../../../api/api';
import { useFormik } from 'formik';

import PulseLoader from "react-spinners/PulseLoader";
import useLocalStorage from '../../../hooks/useLocalStorage';
import FlagsDropDown from '../FlagsDropDown';

const Login = ({ modalIsOpen, setModalIsOpen, setValidSMSModalIsOpen, setPhoneLogin }) => {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState('il')

    const getCodeArea = () => {
        switch(selectedFlag){
            case "il":
                return ""
            case "us":
                return "+1"
            case "uk":
                return "+44"
            case "fr":
                return "+33"
        }
    }

    const onSubmit = async values => {
        errorMsg && setErrorMsg('');
        setIsLoading(true);
        const data = {
            phone: getCodeArea() + values.phone,
        }
        const resp = await loginParentResp(data);
        
        if(resp && !resp.code){
            dispatch({ type: "SET_ACTIVE_SCREEN", value: 'KitCode' }) //update next screen 
            dispatch({ type: "SET_USER_TOKEN", value: resp.token })

            errorMsg && setErrorMsg('');
            setPhoneLogin(data.phone)
            setValidSMSModalIsOpen(true);
            setModalIsOpen(false)
        }
        else if(resp && resp.code){
            setErrorMsg('המספר אינו תקין! נסו שוב.')
        }
        else{
            setErrorMsg('משהו השתבש! נסו שוב.')
        }

        console.log('login-parent resp:', resp)
        setIsLoading(false)
    }

    const initialValues = {
        phone: ''
    }

    const validate = values => {
        let errors = {};

        if(!values.phone){
            errors.phone = 'שדה ריק!'
        }
        // else if(!/^05[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(values.phone)){
        //     errors.phone = 'מספר לא תקין!'
        // }
        return errors;
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: '#4c4c4c',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '20%',
                        backgroundColor: '#f5f0db',
                        border: '.3vw solid #FFF',
                        borderRadius: '.3vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.bgImage}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={smsValidBg}
                    width="105%"
                    height="110%"
                    draggable={false}
                    />
                </div>
                <div className={style.closeBtn} onClick={() => setModalIsOpen(false)}>
                    <img src={closeBtn} alt="" width="100%"/>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className={style.logoBtn}>
                        <img src={logo} width='100%' height='100%' draggable={false} alt='' />
                    </div>
                    <div className={style.title}>יש לך חשבון? מתחברים כאן</div>
                    <div className={style.subtitle}>הזן/י את הטלפון שלך, לטלפון זה אנחנו נשלח לך</div>
                    <div className={style.subtitle}>קוד לאימות החשבון</div>
                    <div className={style.phoneInput}>
                        <label>טלפון</label>
                        <div className={style.phoneInputFields}>
                            <input type='text' id='phone' name='phone'
                                placeholder='הקלד/י...' 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                                style={{border: formik.errors.phone && formik.touched.phone ? '.2vh solid #FF0000' : ''}}
                            />
                            <div className={style.phoneCodeArea}>
                                <FlagsDropDown
                                    onChange={flag => setSelectedFlag(flag)}
                                    selectedValue={selectedFlag}
                                />
                            </div>
                        </div>
                        {/* {formik.errors.phone && formik.touched.phone ? <div className={style.errorMsg}>{formik.errors.phone}</div> : null} */}

                    </div>
                    <div className={style.btn} onClick={isLoading ? null : formik.handleSubmit}>שלחו לי קוד אימות</div>
                    <div className={style.spinner}>
                        <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size='.8vw' />
                    </div>
                    {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                </form>
            </div>
        </Modal>
    )
}

export default Login
