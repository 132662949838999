import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Logout = ({ modalIsOpen, setModalIsOpen }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const history = useHistory();

    const handleYesClick = () => {
        localStorage.clear();

        dispatch({ type: "SET_ACTIVE_SCREEN", value: 'MainScreen' })
        dispatch({ type: "SET_IS_CONNECTED", value: false })

        history.push('/');
        setModalIsOpen(false);
    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(0,0,0,.3)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '22%',
                        height: '25%',
                        backgroundColor: '#FFF',
                        // border: '.3vw solid #FFF',
                        borderRadius: '1vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.title}>{`היי ${userInfo.name},`}</div>
                <div className={style.question}>האם את/ה בטוח/ה שברצונך להתנתק?</div>
                <div className={style.btns}>
                    <div className={style.btn} onClick={() => setModalIsOpen(false)}>לא</div>
                    <div className={style.btn} onClick={handleYesClick}>כן</div>
                </div>
            </div>
        </Modal>
    )
}

export default Logout
