import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import logo from '../../../assets/logo/mainLogo.png';
import book from '../../../assets/book.svg';
import play from '../../../assets/bookPlay.png'
import pause from '../../../assets/bookPause.png'
import homeBtn from '../../../assets/homeBtn.svg'
import closeBtn from '../../../assets/closeBtn.svg'
import arrowCircle from '../../../assets/arrowCircle.svg'
import { Link } from 'react-router-dom';
import HTMLFlipBook from 'react-pageflip';

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PulseLoader from "react-spinners/PulseLoader";

//////////////////

// const Page = React.forwardRef((props, ref) => {
//     return (
//         <div className="demoPage" ref={ref}>
//             {/* ref required  */}
//             <h1>Page Header</h1>
//             <p>{props.children}</p>
//             <p>Page number: {props.number}</p>
//         </div>
//     );
// });

// function MyBook(props) {
//     return (
//         <HTMLFlipBook width={300} height={500} border="1px solid red">
//             <Page number="1">Page text</Page>
//             <Page number="2">Page text</Page>
//             <Page number="3">Page text</Page>
//             <Page number="4">Page text</Page>
//         </HTMLFlipBook>
//     );
// }

/////////////////

// const Page = ({pageIdx}) => {
//     return(
//         <div className={style.page}>
//             <LazyLoadImage
//                 effect="blur"
//                 alt={""}
//                 // src={page30}
//                 width="100%"
//                 height="100%"
//                 draggable={false}
//                 style={{position: 'absolute', top: 0, left: 0, right: 0}}
//             />
//         </div>
//         )
// }

//////////////

function MyBook({bookRef, handleFlip}) {
    // console.log('getCurrentPageIndex:',props.getCurrentPageIndex());

    useEffect(() => {
        if(bookRef && bookRef.current){
            console.log("bookRef.current", bookRef.current)
            bookRef.current.pageFlip() && console.log(bookRef.current.pageFlip())
            // bookRef.current.pageFlip() && bookRef.current.pageFlip().turnToPage(27);
        }
    })

    const handleInit = e => {
        console.log('handleInit > e:',e)
        bookRef.current.pageFlip().turnToPage(49) //go to last page (last index)
    }
    
    return (
        <HTMLFlipBook ref={bookRef} width={1000} height={1180} 
                        showCover={false} maxShadowOpacity={0.6}
                        startZIndex={11}
                        mobileScrollSupport={false}
                        // renderOnlyPageLengthChange={true}
                        clickEventForward={false}
                        useMouseEvents={false} //book flipping animation
                        onFlip={handleFlip}
                        onInit={handleInit}
                        size="stretch"
                        minWidth="100%"
                        maxWidth="100%"
                        minHeight="100%"
                        maxHeight="100%"
                    >
            
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/50.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/49.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/48.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/47.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/46.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/45.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/44.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/43.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/42.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/41.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/40.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/39.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/38.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/37.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/36.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/35.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/34.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/33.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/32.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/31.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/30.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/29.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/28.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/27.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/26.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/25.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/24.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/23.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/22.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/21.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/20.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/19.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/18.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/17.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/16.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/15.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/14.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/13.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/12.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/11.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/10.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/9.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/8.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/7.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/6.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/5.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/4.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/3.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/2.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
            <div className={style.page}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={require(`../../../assets/book-imgs/1.png`).default}
                        width="100%"
                        height="100%"
                        draggable={false}
                        style={{position: 'absolute', top: 0, left: 0, right: 0}}
                    />
            </div>
        </HTMLFlipBook>
    );
}

const INITIAL_PAGE = 1;

const BookModal = ({ modalIsOpen, setModalIsOpen }) => {
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
    const [isPlaying, setIsPlaying] = useState(false)
    const [isLoadingImgs, setIsLoadingImgs] = useState(true);
    const [pagingEffect, setPagingEffect] = useState(false)
    const bookRef = useRef();
    const playerRef = useRef();
    const pagingEffectRef = useRef();

    useEffect(() => {
        if(playerRef && playerRef.current){
            isPlaying ? playerRef.current.play() : playerRef.current.pause();
        }
    },[isPlaying])

    useEffect(() => {
        if(pagingEffectRef && pagingEffectRef.current){
            pagingEffect ? pagingEffectRef.current.play() : pagingEffectRef.current.pause();
        }
    },[pagingEffect])

    const handleRightClick = () => {
        setPagingEffect(true)
        if(bookRef && bookRef.current){
            setCurrentPage(prev => prev-1)
            bookRef.current.pageFlip().flipNext()
        }
    }
    const handleLeftClick = () => {
        setPagingEffect(true);
        if(bookRef && bookRef.current){
            setCurrentPage(prev => prev+1)
            bookRef.current.pageFlip().flipPrev()
        }
    }

    const handleFlip = e => {
        const lastPageIndex = 49;
        console.log("handleFlip > e.data:", e.data)
        console.log("currentPage:", currentPage)
        console.log("Math.ceil((INITIAL_PAGE-e.data) / 2):",Math.ceil((lastPageIndex-e.data) / 2))
        if(Math.ceil((lastPageIndex-e.data) / 2) > currentPage){
            console.log("left")
        }
        else{
            console.log("right")
        }
    }


    const handleCloseClick = () => {
        setCurrentPage(INITIAL_PAGE);
        setIsPlaying(false);
        setModalIsOpen(false)
    }

    const handleEndedAudio = () => {
        setIsPlaying(false);
        // setCurrentPage(prev => prev+1);
        // bookRef.current.pageFlip().flipPrev();
    }
    const handleEndedPagingEffect = () => {
        setPagingEffect(false);
    }

    return (
        <Modal
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        zIndex: 10,
                        backgroundColor: '#C9E7EC',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#000',

                    },
                    content: {
                        width: '100%',
                        // height: '100%',
                        backgroundColor: '#d6ebef',
                        outline: 'unset',
                        overflow: 'hidden'
                    }
                }
            }>
            <div className={style.container}>
                <Link to='/content-buttons'>
                    <div className={style.homeBtn}>
                        <img src={homeBtn} width='100%' alt='' />
                    </div>
                </Link>
                {
                    currentPage > 1
                    ?
                        <div className={style.RightArrowCircle} onClick={handleRightClick}>
                            <div className={style.rightPageNumber}>{currentPage-1}</div>
                            <img src={arrowCircle} width='60%' alt='' />
                        </div>
                    :
                        null
                }
                {
                    currentPage < 25
                    ?
                        <div className={style.LeftArrowCircle} onClick={handleLeftClick}>
                            <img src={arrowCircle} width='60%' alt='' />
                            <div className={style.leftPageNumber}>{currentPage+1}</div>
                        </div>
                    :
                        null
                }
                <div className={style.closeBtn} onClick={handleCloseClick}>
                    <img src={closeBtn} width='100%' alt='' />
                </div>
                <div className={style.bookContainer}>
                    <MyBook bookRef={bookRef}
                            handleFlip={handleFlip}
                            />
                    <div  className={style.controlBtn} onClick={() => setIsPlaying(prev => !prev)}>
                        <img src={isPlaying ? pause : play} width='100%' alt='' />
                    </div>
                </div>
                
            </div>
           
            {
                pagingEffect
                ?
                    <audio onEnded={handleEndedPagingEffect}
                            src={require(`../../../assets/book-audio/pagingEffect.mp3`).default}
                            autoPlay={true}
                            ref={pagingEffectRef}
                    />
                :
                    null
            }
           {
                isPlaying
                ?
                    <audio onEnded={handleEndedAudio}
                            src={require(`../../../assets/book-audio/${currentPage}.mp3`).default}
                            autoPlay={true}
                            ref={playerRef}/>
                :
                    null
            }
        </Modal>
    )
}

export default BookModal


{/* <div className={style.bookBorderImg}>
    <LazyLoadImage
        effect="blur"
        alt={""}
        src={book}
        width="100%"
        height="100%"
        draggable={false}
    />
</div> */}
{/* <div className={style.spinner}>
    <PulseLoader color='rgba(0,0,0,.2)' loading={isLoadingImgs} size='.8vw' />
</div> */}