import React from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import "react-lazy-load-image-component/src/effects/blur.css";

import PulseLoader from "react-spinners/PulseLoader";

const MainScreenLoader = ({ modalIsOpen }) => {


    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(255,255,255,.85)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        zIndex: 100,
                        border: "none",
                        outline: "none"
                    }
                }
            }>
                <div className={style.container}>
                    <PulseLoader loading={modalIsOpen} size='1.5vw'  color="rgba(0,0,0,.6)" margin=".6vw"/>
                </div>
        </Modal>
    )
}

export default MainScreenLoader
