import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import style from './style.module.css';
import MyDrawings from './MyDrawings';
import DrawingBoard from './DrawingBoard';
import { useHistory } from 'react-router-dom';

const Drawing = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const [isMyDrawings, setIsMyDrawings] = useState(false);

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Games' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Games' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'orange' && dispatch({ type: "CHANGE_BG_COLOR", value: 'orange' })
    },[headerColor])

    return (
        <div className={style.container}>
            {
                isMyDrawings
                ?
                    <MyDrawings setIsMyDrawings={setIsMyDrawings}/>
                :
                    <DrawingBoard setIsMyDrawings={setIsMyDrawings}/>
            }
        </div>
    )
}

export default Drawing
