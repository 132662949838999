import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import logo from '../../../assets/logo/mainLogo.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import newUserBg from '../../../assets/bg/newUserBg.png';
import { useFormik } from 'formik';
import closeBtn from '../../../assets/closeBtn.svg';
import PulseLoader from "react-spinners/PulseLoader";
import { regChildResp } from '../../../api/api';
import { useDispatch } from 'react-redux';
import FlagsDropDown from '../FlagsDropDown';

const validate = values => {
    let errors = {};
    
    if(!values.name){
        errors.name = 'שדה ריק!'
    }

    if(!values.phone){
        errors.phone = 'שדה ריק!'
    }
    // else if(!/^05[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(values.phone)){
    //     errors.phone = 'מספר לא תקין!'
    // }
    
    return errors;
}

const initialValues = {
    name: '',
    phone: '',
}

const AddUser = ({ modalIsOpen, setModalIsOpen, setValidSMSModalIsOpen, setParentPhone}) => {
    const dispatch = useDispatch();
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFlag, setSelectedFlag] = useState('il')

    const getCodeArea = () => {
        switch(selectedFlag){
            case "il":
                return ""
            case "us":
                return "+1"
            case "uk":
                return "+44"
            case "fr":
                return "+33"
        }
    }
    
    const onSubmit = async values => {
        errorMsg && setErrorMsg('');
        setIsLoading(true);
        const phoneFormated = getCodeArea() + values.phone;
        const data = {...values, phone: phoneFormated}

        const resp = await regChildResp(data, setIsLoading, setErrorMsg);
        console.log('regChildResp > resp:', resp)
        
        if(resp && !resp.code){
            dispatch({ type: "SET_CHILD_NAME", value: values.name })
            setParentPhone(data.phone);
            setValidSMSModalIsOpen(true);
            setModalIsOpen(false);
        }
        else if(resp?.error === 'CHILD_MAX_COUNT_REACHED'){
            setErrorMsg('לא ניתן להוסיף משתמשים נוספים (מקסימום: 3)')
        }
        else if(resp?.error === 'PARENT_NOT_FOUND'){
            setErrorMsg('שגיאה! משתמש אינו קיים')
        }
        else if(resp && resp.code){
            setErrorMsg('שגיאה! משתמש זה כבר קיים במכשיר אחר')
        }
        else{
            setErrorMsg('משהו השתבש! נסו שוב')
        }

        setIsLoading(false);
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    return (
        <Modal
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: '#373737',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '20%',
                        height: 'max-content',
                        maxHeight: '70vh',
                        minHeight: '50vh',
                        backgroundColor: '#d6ebef',
                        border: '.3vw solid #FFF',
                        borderRadius: '.3vw',
                        outline: 'unset',
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.bgImage}>
                    <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={newUserBg}
                    width="100%"
                    draggable={false}
                    />
                </div>
                <div className={style.closeBtn} onClick={() => setModalIsOpen(false)}>
                    <img src={closeBtn} alt="" width="70%"/>
                </div>
                <form onSubmit={isLoading ? null : formik.handleSubmit}>
                    <img src={logo} width='30%' draggable={false} alt='' />
                    <div className={style.title}>היי, הגיע הזמן שעוד מישהו יצטרף לחגיגה</div>
                    <div className={style.subtitle}>אנחנו רק צריכים את השם ואת מספר הטלפון כדי שיהיה</div>
                    <div className={style.subtitle}>אפשרי לחבר אותו לחשבון שלך</div>
                    <div className={style.nameInput}>
                        <label>שם מלא</label>
                        <input type='text' placeholder='הקלד/י...' 
                            name='name'
                            id='name'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                            style={{border: formik.errors.name && formik.touched.name ? '.2vh solid #FF0000' : ''}}
                            />
                        {/* {formik.errors.name && formik.touched.name ? <div className={style.errorMsg}>{formik.errors.name}</div> : null} */}
                    </div>
                    <div className={style.phoneInput}>
                        <label>טלפון</label>
                        <div className={style.phoneInputFields}>
                            <div className={style.phoneCodeArea}>
                                <FlagsDropDown
                                    onChange={flag => setSelectedFlag(flag)}
                                    selectedValue={selectedFlag}
                                />
                            </div>
                            <input type='text' placeholder='הקלד/י...'
                                name='phone'
                                id='phone'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.address}
                                style={{border: formik.errors.phone && formik.touched.phone ? '.2vh solid #FF0000' : ''}}
                            />
                        </div>
                        {/* {formik.errors.phone && formik.touched.phone ? <div className={style.errorMsg}>{formik.errors.phone}</div> : null} */}
                    </div>
                    <div className={style.btn}>
                        <button type="submit" disabled={isLoading} className={style.btn} onClick={isLoading ? null : formik.handleSubmit}>אישור והוספה</button>
                    </div>
                    {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                    <div className={isLoading ? style.spinner : null}>
                        <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size='.8vw' />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default AddUser
