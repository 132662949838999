import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import { Link } from 'react-router-dom';
import { faqList } from '../../QA'
import useInterval from '../../../hooks/useInterval';

const QAForm = () => {
    const [faqCounter, setFaqCounter] = useState(0);

    useInterval(() => {
        setFaqCounter(prev => (prev + 1) % faqList.length);
    },8000);

    return (
        <div className={style.container}>
            <div className={style.title}>שאלות נפוצות</div>
            <div className={style.content}>
                <div className={style.question}>{Object.keys(faqList[faqCounter])[0]}</div>
                <div className={style.answer}>{Object.values(faqList[faqCounter])[0]}</div>
            </div>
            <div className={style.dots}>
                {
                    faqList.map((_, idx) => 
                        <div className={style.dot} key={idx}
                            onClick={() => setFaqCounter(idx)}
                            style={{width: faqCounter === idx ? '1.6%' : '0',
                                    backgroundColor: faqCounter === idx ? '#000' : '#FFF',
                                    border: faqCounter === idx ? '.3vw solid #000' : '.3vw solid #FFF'}}
                            />
                    )
                }
            </div>
            <Link to='/qa'>
                <div className={style.gotoQAPageButton}>צפייה בכל השאלות</div>
            </Link>
        </div>
    )
}

export default QAForm
