import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import style from './style.module.css';
import play from '../../../assets/bluePlayBtn.svg';
import ReactPlayer from 'react-player/lazy';
import ReactHlsPlayer from 'react-hls-player';
import Turtle from '../../../assets/tv/Turtle.m3u8'
import { useHistory } from 'react-router-dom';
// import ch1_bg from '../../../assets/bg/tv-bg/1.jpg'

export const videoList = [
    {id: 1, name: 'איך עוד לא המציאו?', guest: 'שירי מימון', time: '02:46'},
    {id: 2, name: 'ילדטבע', guest: 'ישראל קטורזה', time: '02:21'},
    {id: 3, name: 'להיות אני', guest: 'לי בירן', time: '02:22'},
    {id: 4, name: 'צב הוא צב', guest: 'טלי אורן', time: '02:31'},
    {id: 5, name: 'בטן גדולה', guest: 'ציפי שביט', time: '02:21'},
    {id: 6, name: 'מנחם המנמנם', guest: 'רוני דלומי', time: '03:01'},
    {id: 7, name: 'מתי כבר מגיעים?', guest: 'יובל המבולבל', time: '02:29'},
    {id: 8, name: 'תפילות הילדים', guest: 'מירי מסיקה', time: '03:16'},
    {id: 9, name: 'שטויות שגדולים אומרים', guest: 'אליעד נחום', time: '02:35'},
    {id: 10, name: 'עולם חדש', guest: 'משה פרץ', time: '03:52'},
    {id: 11, name: 'לכל אחד יש לב', guest: 'הראל סקעת', time: '03:03'},
    {id: 12, name: 'מתנות קטנות', guest: 'להקת שלווה ונעם חורב', time: '04:03'},
]

export const getGuestFormat = (guestName, videoId) => {
    if(videoId === 1 || videoId === 4 || videoId === 5 || videoId === 6 ||videoId === 8){
        return `אורחת מיוחדת: ${guestName}`
    }
    else if(videoId === 12){
        return `אורחים מיוחדים: ${guestName}`
    }
    else{
        return `אורח מיוחד: ${guestName}`
    }
}

const reactPlayerStyle = {
    overflow: "hidden",
    border: ".25vw solid rgba(77,105,169,.8)",
    // backgroundColor: "#F9F9F9",
    borderRadius: "2vw",
    // width: "100%",
    // height: "100%",
    backgroundColor: 'rgba(0,0,0,.2)',
    boxShadow: "0vw 0vw .8vh rgba(77,105,169,.2)"
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
}

export const getPlayIcon = (id, redPlayBtn)=> {
    return(
        <div className={style.playIcon} style={{width: "100%", height: "100%", position: "relative"}}>
            <div className={style.playBtn} style={{position: "absolute", top: 0, bottom: 0, left: 0,
                                                   right: 0, display: 'flex', justifyContent: "center",
                                                   alignItems: "center"}}>
                <img src={redPlayBtn ? redPlayBtn : play} alt="" width='20%'/>
            </div>
            <img width="100%" height="100%" alt="" 
                 src={require(`../../../assets/bg/tv-bg/${id}.jpg`).default} />
        </div>
    )
}

const Tv = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    const playerRef = useRef(null);

    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])
    
    useEffect(() => {
        activeScreen !== 'Tv' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Tv' })
    },[activeScreen])

    useEffect(() => {
        headerColor !== 'blue' && dispatch({ type: "CHANGE_BG_COLOR", value: 'blue' })
    },[headerColor])
    
    useEffect(() => {
        if(playerRef && playerRef.current){
            console.log('playerRef:',playerRef);
            playerRef.current.autoPlay = true;
            console.log(playerRef.current.isPlaying)
        }
    },[playerRef])

    // url='https://matanotk.s3.eu-central-1.amazonaws.com/generated/Turtle.m3u8'

    return (
        <div>
            <div className={style.container}>
                <div className={style.videos}>
                {
                    videoList.map(video => {
                        return(
                            <div className={style.video} key={video.id}>
                                {/* <div className={style.window}> */}
                                    {/* <img src={play} alt="" width='20%'/> */}
                                    <ReactPlayer width="100%" height="100%"
                                        url={`https://matanotk.s3.eu-central-1.amazonaws.com/series/ep${video.id > 9 ? video.id : "0" + video.id}/ep${video.id > 9 ? video.id : "0" + video.id}.m3u8`}
                                        controls={true}
                                        autoPlay={false}
                                        onStart={() => {console.log("onstart!!!!")}}
                                        // style={{border: '.15vw solid #FFF', borderRadius: '1vw',
                                        //         overflow: 'hidden', width:"100%" ,cursor: 'pointer',
                                        //         backgroundColor: 'rgba(0,0,0,.8)'}}
                                        loop={false}
                                        light={true}
                                        // playIcon={<img width="100%" height="100%" alt="" src={require(`../../../assets/bg/tv-bg/${video.id}.jpg`).default} />}
                                        playIcon={getPlayIcon(video.id)}
                                        style={reactPlayerStyle}
                                    />

                                {/* </div> */}
                                <div className={style.details}>
                                    <div className={style.name}>פרק {video.id} - {video.name}</div>
                                    <div className={style.guest}>{getGuestFormat(video.guest, video.id)}</div>
                                </div>
                            </div>
                        )
                    })
                }
                    {/* <div className={style.video}> */}
                        {/* <ReactPlayer className='react-player'
                            url='https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8'
                            playing
                            ref={playerRef}
                            width='100%'
                            style={videoStyle}
                            controls
                            config={{
                                file: {
                                    forceHLS: true,
                                }
                            }}
                        /> */}

                        {/* <video autoPlay={true} loop controls >
                            <source src={Turtle} type="application/x-mpegURL/m3u8" />
                        </video> */}

                        {/* <ReactHlsPlayer
                            src="https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8"
                            controls={true}
                            width="100%"
                            height='auto'
                            style={videoStyle}
                            // poster={play}
                            playerRef={playerRef}
                        />,
                        
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default Tv
