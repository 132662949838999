import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';

// const VAL_1 = 8;
// const VAL_2 = 7;

const exers = [{v1: 8, v2: 7, op: 'mult'}, {v1: 4, v2: 9, op: 'mult'}
            ,{v1: 81, v2: 9, op: 'div'}, {v1: 100, v2: 52, op: 'sub'}, {v1: 36, v2: 6, op: 'div'}]

const MathExe = ({ modalIsOpen, setModalIsOpen }) => {
    const userInfo = useSelector((state) => state.userInfo);
    const [errorMsg, setErrorMsg] = useState('');
    const [result, setResult] = useState('');
    const [exer] = useState(exers[Math.floor(Math.random() * exers.length)]);

    const getOpSymbol = () => {
        if(exer.op === 'mult'){
            return 'X';
        }
        else if(exer.op === 'div'){
            return '/';
        }
        else{
            return '-';
        }
    }

    const getExerResult = () => {
        const {v1, v2, op} = exer;

        if(op === 'mult'){
            return v1 * v2;
        }
        else if(op === 'div'){
            return v1 / v2;
        }
        else{
            return v1 - v2;
        }
    }

    const handleSubmit = e => {
        e.preventDefault();
        const userResult = parseInt(result);
        const exerResult = getExerResult();
        if(userResult === exerResult){
            setModalIsOpen(false);
        }
        else{
            setErrorMsg("אופס...אנא נסה/י שוב")
        }
    }

    const handleChange = e => {
        errorMsg && setErrorMsg('');
        
        if(e.target.value.length > 3 || !/^[0-9]*$/.test(e.target.value)){
            return;
        }
        else{
            setResult(e.target.value);
        }
    }

    return (
        <Modal 
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        width: '100vw',
                        height: '100vh',
                        zIndex: 10,
                        backgroundColor: 'rgba(0,0,0,.3)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '25%',
                        height: '50%',
                        backgroundColor: '#FFF',
                        borderRadius: '1vw',
                        outline: 'unset',
                        zIndex: 100,
                    }
                }
            }>
            <div className={style.container}>
                <form onSubmit={handleSubmit}>
                    <div className={style.title}>{`היי ${userInfo.name},`}</div>
                    <div className={style.infoWrapper}>
                        <div className={style.info}>רק כדי לוודא שלא תעשה פעולה לא</div>
                        <div className={style.info}>רצויה באפליקציה, נבקש ממך לפתור את</div>
                        <div className={style.info}>התרגיל הבא על מנת להיכנס לפרופיל</div>
                    </div>
                    <div className={style.exerciseWrapper}>
                        <div className={style.exercise}>
                            <div className={style.question}>{`${exer.v1}${getOpSymbol()}${exer.v2} = `}</div>
                            <div className={style.answer} style={{border: errorMsg ? '.1vw solid #FF0000' : '.1vw solid #000'}}>
                                <input type="number" value={result} onChange={handleChange} maxLength={3} />
                            </div>
                        </div>
                        {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                    </div>
                    <button type="submit" onClick={handleSubmit}>המשך</button>
                </form>
            </div>
        </Modal>
    )
}

export default MathExe
