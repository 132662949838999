import React from 'react'
import style from './style.module.css'

const MyDrawings = ({setIsMyDrawings}) => {
    return (
        <div className={style.container}>
            <button onClick={() => setIsMyDrawings(false)}>לוח ציורים</button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className={style.noDrawings}>אין ציורים</div>
        </div>
    )
}

export default MyDrawings
