import React,{ useEffect, useState } from 'react'
import style from './style.module.css'
import { useDispatch, useSelector } from 'react-redux';
import BookModal from '../../modals/BookModal';
import bookBg from '../../../assets/book-imgs/bookBg.png';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import book from '../../../assets/book-imgs/bookIntro.png';
import { useHistory } from 'react-router-dom';

const Book = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const headerColor = useSelector((state) => state.headerColor);
    const activeScreen = useSelector((state) => state.activeScreen);
    
    useEffect(() => { //checks for page refreshing 
        if (window.performance) {
            if (performance.navigation.type == 1) {
              history.push('/')
            }
        }
    },[])

    useEffect(() => {
        activeScreen !== 'Book' && dispatch({ type: "SET_ACTIVE_SCREEN", value: 'Book' })
    },[activeScreen])
    
    useEffect(() => {
        headerColor !== 'bPink' && dispatch({ type: "CHANGE_BG_COLOR", value: 'bPink' })
    },[headerColor])
    
    return (
        <div className={style.container}>
            <div className={style.modal}>
                <BookModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
            </div>
            <div className={style.book}>
                <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={book}
                    width="100%"
                    draggable={false}
                />
            </div>
            <div className={style.content}>
                <div className={style.title}>מתנות קטנות – שירים לקטנים ולגדולים</div>
                <div className={style.description}>
                    ספר ילדים הכולל 12 שירים חדשים שכתב נעם חורב והלחין
                    <br />
                    רמי קלינשטיין, לצד קטעים נוספים.
                    {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolore numquam similique molestias aliquid. Necessitatibus sint sequi, vitae enim, aliquid eos quisquam temporibus illo, suscipit veniam adipisci? Laboriosam fuga fugit distinctio? */}
                    {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doam similique molestias aliquid. Necessitatibus sint sequi, vitae enim, aliquid eos quisquam temporibus illo, suscipit veniam adipisci? Laboriosam fuga fugit distinctio? */}
                </div>
                <div className={style.btn} onClick={() => setModalIsOpen(true)}>פתח את הספר</div>
            </div>
            <div className={style.bgImage}>
                <LazyLoadImage
                    effect="blur"
                    alt={""}
                    src={bookBg}
                    width="100%"
                    draggable={false}
                />
            </div>
        </div>
    )
}

export default Book
