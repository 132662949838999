import React, { useState } from 'react'
import Modal from 'react-modal';
import style from './style.module.css';
import logo from '../../../assets/logo/mainLogo.png';
import closeBtn from '../../../assets/closeBtn.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import editUserInfoBg from '../../../assets/bg/editUserInfoBg.png';
import { updateUserResp } from '../../../api/api';
import { useFormik } from 'formik';

import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch, useSelector } from 'react-redux';

const validate = values => {
    let errors = {};
    
    if(!values.name.trim()){
        errors.name = 'שדה ריק!'
    }

    else if(values.name.length > 20 && !/^[א-ת\s]+$/.test(values.name.trim())){
        errors.name = 'שדה לא תקין!'
    }

    if(!values.phone.trim()){
        errors.phone = 'שדה ריק!'
    }
    else if(!/^05[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]$/.test(values.phone)){
        errors.phone = 'מספר לא תקין!'
    }

    if(!values.address.trim()){
        errors.address = 'שדה ריק!'
    }
    // else if(!/^[0-9א-ת\,\s]$/.test(values.address)){
    //     errors.address = 'ישנם תווים לא תקינים'
    // }
    else if(values.address.length > 50){
        errors.address = 'חריגה מכמות התווים (50)'
    }
    
    if(!values.email){
        errors.email = 'שדה ריק!'
    }
    // else if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(errors.email)){
    //     errors.email = 'מייל לא תקין!'
    // }

    return errors;
}

const EditUserInfo = ({ modalIsOpen, setModalIsOpen }) => {
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.userInfo);
    const userToken = useSelector((state) => state.userToken);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const onSubmit = async values => {
        errorMsg && setErrorMsg('');
        setIsLoading(true);

        const resp = await updateUserResp(values, userToken, setIsLoading, setErrorMsg)
        console.log('updateUserResp:', resp)
        if(resp && !resp.code){
            localStorage.setItem('userInfo', JSON.stringify(values));
            dispatch({ type: "UPDATE_USER_INFO", value: values });
            setModalIsOpen(false);
        }
        else{
            setErrorMsg('שגיאה! אחד מהפרטים אינו תקין.')
        }

        setIsLoading(false);
    }
    const initialValues = {
        name: userInfo.name,
        phone: userInfo.phone,
        email: userInfo.email,
        address: userInfo.address,
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    return (
        <Modal
            isOpen={modalIsOpen} 
            className={style.container}
            style={
                {
                    overlay: {
                        zIndex: 10,
                        backgroundColor: '#afafaf',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    content: {
                        width: '25%',
                        // height: '72%',
                        backgroundColor: '#d6ebef',
                        border: '.4vw solid #FFF',
                        borderRadius: '.5vw',
                        outline: 'unset',
                    }
                }
            }>
            <div className={style.container}>
                <div className={style.bgImage}>
                    <LazyLoadImage
                        effect="blur"
                        alt={""}
                        src={editUserInfoBg}
                        width="100%"
                        draggable={false}
                    />
                </div>
                <div className={style.closeBtn} onClick={() => setModalIsOpen(false)}>
                    <img src={closeBtn} width='100%' alt='' />
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <img src={logo} width='30%' draggable={false} alt='' />
                    <div className={style.title}>עריכת הפרטים שלך</div>

                    <div className={style.nameInput}>
                        <label htmlFor='name'>שם מלא</label>
                        <input type='text' id='name' name='name' 
                            placeholder='הקלד/י...' 
                            onChange={formik.handleChange} 
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            style={{border: formik.errors.name && formik.touched.name ? '.2vh solid #FF0000' : ''}}
                            />
                    </div>
                    <div className={style.phoneInput}>
                        <label htmlFor='phone'>טלפון</label>
                        <input type='text' id='phone' name='phone'
                            placeholder='הקלד/י...'
                            disabled={true}
                            // onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            style={{color: '#808080', backgroundColor: '#eeeeee', border: '.2vh solid #eeeeee'}}
                            />
                    </div>
                    <div className={style.mailInput}>
                        <label htmlFor='email'>מייל</label>
                        <input type='text' id='email' name='email'
                            placeholder='הקלד/י...'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            style={{border: formik.errors.email && formik.touched.email ? '.2vh solid #FF0000' : ''}}
                            />
                    </div>
                    <div className={style.addressInput}>
                        <label htmlFor='address'>כתובת</label>
                        <input type='text' id='address' name='address'
                            placeholder='הקלד/י...'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                            style={{border: formik.errors.address && formik.touched.address ? '.2vh solid #FF0000' : ''}}
                            />
                    </div>
                    <button type="submit" disabled={isLoading} className={style.btn} onClick={isLoading ? null : formik.handleSubmit}>שמירת שינויים</button>
                    {errorMsg ? <div className={style.errorMsg}>{errorMsg}</div> : null}
                    <div className={style.spinner}>
                        <PulseLoader color='rgba(0,0,0,.2)' loading={isLoading} size='.8vw' />
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default EditUserInfo
